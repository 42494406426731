/*! ===================================
 *  Author: Nazarkin Roman, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

'use strict';

(function ($) {
    var $elems = $('.sp-animate-numbers h2');

    // set roller numbers first
    $elems.each(function (i) {
        $(this).attr('data-slno', i);
        $(this).addClass('roller-title-number-' + i);
    });

    // run when appear
    $elems.one('appear', function () {
        numberRoller($(this).attr('data-slno'));
    });

    $(window).one('pzt.preloader_done', function() {
        $elems.appear({force_process: true});
    });

    function numberRoller(slno) {
        var min = $('.roller-title-number-' + slno).attr('data-min');
        var max = $('.roller-title-number-' + slno).attr('data-max');
        var timediff = $('.roller-title-number-' + slno).attr('data-delay');
        var increment = $('.roller-title-number-' + slno).attr('data-increment');
        var numdiff = max - min;
        var timeout = (timediff * 1000) / numdiff;
        numberRoll(slno, min, max, increment, timeout);
    }

    function numberRoll(slno, min, max, increment, timeout) {//alert(slno+"="+min+"="+max+"="+increment+"="+timeout);
        if (min <= max) {
            $('.roller-title-number-' + slno).html(min);
            min = parseInt(min, 10) + parseInt(increment, 10);
            setTimeout(function () {
                numberRoll(parseInt(slno, 10), parseInt(min, 10), parseInt(max, 10), parseInt(increment, 10), parseInt(timeout, 10))
            }, timeout);
        } else {
            $('.roller-title-number-' + slno).html(max);
        }
    }

})(jQuery);